import SvgArrowRight from '@sats-group/icons/18/arrow-right';
import React from 'react';

import Tag from 'sats-ui-lib/react/tag/tag';
import Text from 'sats-ui-lib/react/text/text';

import { Employee as Props } from './employee.props';

const Employee: React.FC<Props> = ({ href, id, name, clubName }) => {
  return (
    <a className="employee" href={href} data-id={id}>
      <div className="employee__info">
        <Text>{name}</Text>
        {clubName ? (
          <Tag
            variant={Tag.variants.information}
            text={clubName}
            size={Tag.sizes.xs}
          />
        ) : null}
      </div>
      <SvgArrowRight />
    </a>
  );
};

export default Employee;

import AddIcon from '@sats-group/icons/32/add';
import React, { useState } from 'react';

import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Text from 'sats-ui-lib/react/text';

import Spinner from '../spinner/spinner';

import type { DiscountCode as Props } from './discount-code.props';

const DiscountCode: React.FunctionComponent<Props> = ({
  endpoint,
  gatekeeperText,
  hiddenInputs = [],
  name,
  placeholderText,
  submitButtonText,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    setIsLoading(true);
  };

  return (
    <div className="discount-code">
      {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
      {isClicked ? (
        <form action={endpoint} method="POST" onSubmit={handleSubmit}>
          {hiddenInputs.map(input => (
            <HiddenInput key={input.name} {...input} />
          ))}
          <div className="discount-code__inputs">
            <input
              type="text"
              placeholder={placeholderText}
              aria-label={placeholderText}
              name={name}
              required={true}
            />
            <button type="submit" disabled={isLoading ? true : false}>
              <Text theme={Text.themes.emphasis}>{submitButtonText}</Text>
            </button>
          </div>
        </form>
      ) : (
        <button
          className="discount-code__initial-state"
          onClick={() => setIsClicked(true)}
        >
          <Text>{gatekeeperText}</Text>
          <AddIcon />
        </button>
      )}
    </div>
  );
};

export default DiscountCode;

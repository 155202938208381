import Check from '@sats-group/icons/16/check';
import Info from '@sats-group/icons/24/info';
import cn from 'classnames';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text/text';

import Spinner from 'shared-ui/components/spinner/spinner';

import type { OptionLinks as Props } from './option-links.props';

const OptionLinks: React.FunctionComponent<Props> = ({ options }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="option-links">
      {loading ? (
        <div className="option-links__spinner">
          <Spinner theme={Spinner.themes.overlay} />
        </div>
      ) : null}
      {options.map(option => (
        <div
          className={cn('option-links__link-wrapper', {
            'option-links__link-wrapper--with-tooltip': option.tooltip,
          })}
          key={option.title}
        >
          {React.createElement(
            option.isSelected ? 'div' : 'a',
            {
              'aria-label': option.ariaLabel,
              className: 'option-links__link',
              key: option.title,
              href: option.isSelected ? undefined : option.link,
              onClick: () => {
                if (!option.isSelected) {
                  setLoading(true);
                }
              },
            },
            <React.Fragment>
              <div
                className={cn('option-links__link-check', {
                  'option-links__link-check--checked': option.isSelected,
                })}
              >
                {option.isSelected ? <Check /> : null}
              </div>
              <Text elementName="h3">{option.title}</Text>
              {option.priceDifference ? (
                <Text>{option.priceDifference}</Text>
              ) : null}
            </React.Fragment>
          )}
          {option.tooltip ? (
            <div className="option-links__tooltip">
              <Button
                leadingIcon={<Info />}
                variant={Button.variants.tertiary}
                onClick={() => setModalOpen(true)}
                {...option.tooltip.button}
              />
              {isModalOpen ? (
                <Modal
                  onClose={() => setModalOpen(false)}
                  {...option.tooltip.modal}
                >
                  <div>
                    <Text>{option.tooltip.text}</Text>
                  </div>
                </Modal>
              ) : null}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default OptionLinks;

import { ObjectValues } from 'sats-ui-lib/react/types';

export const themes = {
  centered: 'centered',
  overlay: 'overlay',
} as const;

export const sizes = {
  medium: 'medium',
  mini: 'mini',
};

export type Spinner = {
  size?: ObjectValues<typeof sizes>;
  theme?: ObjectValues<typeof themes>;
};
